.users {
  width: 100%;

  .header {
    background: white;
    margin-bottom: 12px;
  }
  .userGridView {
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 12px;
    padding-bottom: 24px;
    height: 100%;
    overflow: scroll;
    .filter {
      position: sticky;
      top: 0;
      background: white;
      height: 64px;
      z-index: 100;
    }
    .ant-card-body {
      padding: 12px !important;
    }
  }
}