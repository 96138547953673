.projects {
  width: 100%;
  max-height: calc(100% - 45px);

  .header {
    background: white;
    margin-bottom: 12px;
  }
  .projectGridView {
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 12px;
    padding-bottom: 24px;
    height: 100%;
    overflow: scroll;
    .filter {
      position: sticky;
      top: 0;
      background: white;
      height: 64px;
      z-index: 100;
    }
  }
  .projectItem {
    //height: fi;
    width: fit-content;
    .ant-card-body {
      padding: 12px !important;
    }
  }
  .projectName {

  }
  .grantModal {
    .ant-card-body {
      max-height: 70vh;
      overflow: auto;
    }
  }
}
.createProgramModal {
  .ant-modal-body {
    max-height: 70vh !important;
    overflow: auto;
  }
  .addMetadataBtn {
    position: absolute;
    bottom: -15px;
  }
  .cardRow{
    padding: 12px !important;
  }
  .ant-card-body {
    padding: 0px !important;
  }
  .metadataRow {
    display: flex;
    .rowItem {
      flex: 1;
      margin-right: 8px;
    }
    .rowItemFitContent {
      width: fit-content;
      min-width: 100px;
      margin-right: 8px;

    }
    .rowItemExtraData {
      width: 200px;
      margin-right: 12px;
      margin-bottom: 0px !important;
      .inputNumber {
        width: 100%;
      }

    }
    .rowItemSelectExtraData {
      width: 250px;
      margin-right: 12px;
      margin-bottom: 0px !important;
      .selectType {
        width: 100%;
      }
    }
    .btn {

    }
  }
  .rowItemSelection {
    display: flex;
    gap: 12px;
    margin-bottom: 0px;
    margin-top: 24px;
    overflow: auto;
    flex-wrap: wrap;
    .metaDataRowItems {
      max-width: 40%;
      display: flex;
      gap: 8px;

    }
    .btnRemoveItem{
      margin-left: 8px;
    }
  }
}