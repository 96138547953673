.headerPanel {
  align-items: center;
  background:  #2673dd !important;
  display: flex;
  padding: 0 22px 0 0 !important;
}

.rightContainer {
  margin-left: auto;
}

.user {
  background-color: #fff;
  border: none;
  border-radius: 50%;
  color: #2673dd;
  cursor: pointer;
  height: 32px;
  line-height: 32px;
  overflow: hidden;
  text-align: center;
  width: 32px;
}

.userInfoWrapper {
  align-items: center;
  display: flex;
}

.avatar {
  background-color: var(--primary-color);
  color: #fff;
  margin-right: 12px;
}

.username {
  text-transform: capitalize;
}

.email {
  color: rgba(0, 0, 0, 0.38);
}

.logout {
  padding-left: 0;
}

.logout:hover,
.logout:active,
.logout:focus {
  background: transparent;
}

.regionDrop {
  color: #fff;
  font-size: 18px;
}

.dropIcon {
  margin-left: 4px;
  vertical-align: middle;
}

.logo {
  align-items: center;
  background-color: #2673dd;
  color: white;
  display: flex;
  font-size: 18px;
  height: 32px;
  margin: 16px 8px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.icon {
  font-size: 33px;
  margin-right: 12px;
}

.logoContainer {
  align-items: center;
  background-color: #2673dd;
  display: flex;
  flex-direction: row;
}

.collapse {
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 20px;
  margin-left: 12px;
}
