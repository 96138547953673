@import "~react-image-gallery/styles/scss/image-gallery.scss";
.projectDetail {
  width: 100%;
  max-height: calc(100% - 45px);
  .header {
    background: white;
    margin-bottom: 12px;
  }
  .listItemContainer {
    display: flex;
    justify-content: center;
    position: relative;
    gap: 16px;
    height: 100%;
    width: 100%;
    .listItem {
      width: 550px;
      background: white;
      height: 95%;
      overflow: auto;
      //display: flex;
      .filter {
        position: sticky;
        top: 0px;
        height: 50px;
        background: white;
        z-index: 100;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 6px;
        gap: 6px;
      }
      .ant-list-item {
        align-items: flex-start !important;
        gap: 6px;
        cursor: pointer;
      }
    }
  }
}
.projectContent{
  flex: 1;
  background: white;
  max-height: 95%;
  overflow: auto;
  position: relative;

  .projectContentHeader{
    position: sticky;
    top: 0px;
    height: 50px;
    background: #fce4bd;
    z-index: 100;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px;
    .projectAction{
      display: flex;
      gap: 6px;
    }

  }
  .projectContentForm {
    padding: 12px;
  }
  .ant-form-item {
    margin-bottom: 12px !important;
  }
  .ant-form-item-label {
    font-weight: 500 !important;
    padding: 0px !important;
  }
  .commentAction {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .btn {
      margin-top: 5px;
    }
  }
}
